<template>
  <div :style="{ background: '#FFF' }">
    <Loader />
    <Header />
    <!-- <Banner /> -->
    <div class="wrapper">
      <div :style="{ display: 'flex', justifyContent: 'center' }">
        <MySidebarStyle />
        <div class="content-page" id="content-page" :style="{ width: '100%', minHeight: '80vh' }">
          <transition name="router-anim" enter-active-class="animated  fadeInUp" mode="out-in"
            leave-active-class="animated fadeOut">
            <div class="wrapper" :style="{ width: '100%', height: '80%', margin: '50px 0'}">
              <div
                :style="{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
                <div class="my-width" :style="{ flexDirection: 'row', gap: '1vw', height: '100%' }">
                  <iq-card class="my-side-bar" :style="{borderRadius: '4px', padding: '10px 5px', height: '100%', boxShadow: 'none'}">
                    <MySideMenuChildren />
                  </iq-card>
                  <router-view class="my-side-bar-other" :style="{height: '100%'}"/>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <!-- <LayoutFooter /> -->
    </div>
  </div>
</template>
<script>
// import MyHeaderStyle from '../components/core/partials/HeaderStyle/MyHeaderStyle.vue'
import MySidebarStyle from '../components/core/partials/SidebarStyle/MySidebarStyle1.vue'
// import LayoutFooter from './Components/LayoutFooter.vue'
import Loader from '../components/core/loader/Loader'
import Header from '../page/Home/Header.vue'
// import Banner from '../components/banner/Banner.vue'
// import SetWidth from '../components/setWidth.vue'
import MySideMenuChildren from '../components/core/menus/MySideMenuChildren1.vue'

export default {
  name: 'DashboardLayout',
  components: {
    Loader,
    Header,
    // SetWidth,
    // Banner,
    // MyHeaderStyle,
    MySidebarStyle,
    MySideMenuChildren
    // LayoutFooter
  },
  mounted () {
    document.body.classList = 'two-sidebar'
  },
  methods: {
    change () {
      document.getElementById('my-flex').style.display = 'flex'
    }
  },
  destroyed () {
    // document.body.classList = ''
  }
}
</script>
<style>
@import url("../assets/css/custom.css");
@import url("../assets/css/PriceSlider.css");
</style>
